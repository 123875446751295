
.landing-bento-background {
    background-image:
            radial-gradient(circle farthest-corner at top left, rgb(255, 255, 255) 0%, rgba(225, 243, 97,0) 50%),
            radial-gradient(circle at top 10% right 10%, rgb(131, 226, 255, .25) 0%, rgba(131, 226, 255, 0) 25%),
            radial-gradient(circle at center, rgb(155, 221, 240, .5) 0%, rgba(204, 104, 119, 0) 75%),
            radial-gradient(circle at bottom 0% left 20%, rgba(155, 221, 240,.5) 0%, rgba(155, 221, 240,0) 50%),
            radial-gradient(ellipse at bottom right, rgb(155, 155, 155, .6) 0%, rgba(181, 236, 255, 0) 80%);
}

@keyframes blob {
    0% {
        transform: translate(0, 0) scale(1)
    }
    15% {
        transform: translate(20%, -40%) scale(1.3)
    }
    55% {
        transform: translate(0, 20%) scale(1)
    }
    70% {
        transform: translate(-30%, -15%) scale(0.7)
    }
    100% {
        transform: translate(0, 0) scale(1)
    }
}

.animated-blob {
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-name: blob;
}

.blob-delay-1 {
    animation-delay: 3s;
}

.blob-delay-2 {
    animation-delay: 6s;
}